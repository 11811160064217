
// Packages
import Vue, { defineComponent } from 'vue';

// Static
import nationalities from './static-data/nationalities.json';
import countries from './static-data/countries.json';

// Components
import DropdownWrapper from '../dropdown-wrapper/dropdown-wrapper.vue';
import TextField from '../text-field/text-field.vue';
import TextFieldDate from '../text-field-date/text-field-date.vue';
import PhoneField from '../phone-field/phone-field.vue';
import FileUpload from '../file-upload/file-upload.vue';

import RadioButton from '../radio-button/radio-button.vue';
import IconAlert from '@white-label-icon/icon-alert';
import { add, guessTimezone, sub } from '@white-label-helper/date-utilities';
import BackButton from '../back-button/back-button.vue';
import { NAMED_ROUTES } from '@white-label-configuration/constants';
import { readPiiData } from '@white-label-store/manage-booking';
import { BookingGuestDetailsData } from '@white-label-types/account-booking';
import {
  getShareObjectUrl,
  postGuestDetails,
} from '@white-label-helper/api-manage-booking';

// Mixins
import auth from '@white-label-helper/mixin-auth';
import CheckboxGroup from '../checkbox-group/checkbox-group.vue';
import { merge } from 'lodash';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { minLength, requiredIf } from '@white-label-helper/vuelidate';

export default defineComponent({
  name: 'PostSalesQuestionnaire',
  components: {
    CheckboxGroup,
    RadioButton,
    BackButton,
    TextField,
    TextFieldDate,
    PhoneField,
    DropdownWrapper,
    IconAlert,
    FileUpload,
  },
  mixins: [auth],
  data() {
    return {
      guestPiiToken: '',
      loading: false,
      issuingCountry: { id: '', name: '' },
      nationality: { id: '', name: '' },
      leadAssistance: 'no',
      documentPreviews: {
        passport: '',
        visa: '',
      },
      dropdowns: {
        issuingCountryDropdownOpened: false,
        nationalityDropdownOpened: false,
        specialAssistanceDropdownOpened: false,
      },
      specialAssistance: {
        id: '1',
        name: this.$t('postSales.travelWithAGuide'),
      },
      specialAssistanceDropdown: [
        {
          id: 1,
          name: this.$t('postSales.travelWithAGuide') as string,
        },
      ],
      formData: {
        id: '',
        group: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        dob: '',
        email: '',
        phone: {
          phone_number: '',
          formatted_number: '',
          country_code: 'UK',
        },
        assistance: {
          is_required: false,
          type: '',
        },
        dietary_requirements: {
          is_required: false,
          comment: '',
          list: [],
        },
        uploads: {
          passport: {
            passport_reference: '',
            expiration_date: '',
            issuing_country: '',
            nationality: '',
            file_path: '',
          },
          visa: {
            visa_reference: '',
            file_path: '',
          },
        },
      } as BookingGuestDetailsData,
      visaNeeded: false,
      visaOptions: [
        {
          label: this.$t('postSales.visaNeeded.dontRequired'),
          value: false,
        },
        {
          label: this.$t('postSales.visaNeeded.required'),
          value: true,
        },
      ],
      specialAssistanceNeeded: false,
      assistanceOptions: [
        {
          label: this.$t('postSales.assistanceNotRequired'),
          value: false,
        },
        {
          label: this.$t('postSales.assistanceRequired'),
          value: true,
        },
      ],
      dietaryRequirementsNeeded: false,
      dietaryRequirementsOptions: [
        {
          label: 'I don’t have any dietary requirements',
          value: false,
        },
        {
          label: 'I have dietary requirements',
          value: true,
        },
      ],
      dietaryOptions: [
        {
          label: 'Halal',
          value: 'halal',
        },
        {
          label: 'Gluten intolerance',
          value: 'gluten_intolerance',
        },
        {
          label: 'Kosher',
          value: 'kosher',
        },
        {
          label: 'Vegetarian',
          value: 'vegetarian',
        },
        {
          label: 'Vegan',
          value: 'vegan',
        },
        {
          label: 'Nut allergy',
          value: 'nut_allergy',
        },
      ],
      assistanceTypeOptions: [
        {
          id: 'WCHR',
          name: this.$t('postSales.assistanceOptions.WCHR'),
        },
        {
          id: 'WCHS',
          name: this.$t('postSales.assistanceOptions.WCHS'),
        },
        {
          id: 'WCHC',
          name: this.$t('postSales.assistanceOptions.WCHC'),
        },
        {
          id: 'DPNA',
          name: this.$t('postSales.assistanceOptions.DPNA'),
        },
        {
          id: 'BLND',
          name: this.$t('postSales.assistanceOptions.BLND'),
        },
        {
          id: 'DEAF',
          name: this.$t('postSales.assistanceOptions.DEAF'),
        },
      ],
    };
  },
  validations(): object {
    const passportFields = [
      this.formData.uploads.passport.nationality,
      this.formData.uploads.passport.issuing_country,
      this.formData.uploads.passport.passport_reference,
      this.formData.uploads.passport.expiration_date,
    ];
    const requiredIfAnyDefined = (fields) => {
      return requiredIf(function () {
        return fields.some((field) => field != null && field !== '');
      });
    };
    return {
      formData: {
        uploads: {
          passport: {
            nationality: {
              required: requiredIfAnyDefined(passportFields),
            },
            issuing_country: {
              required: requiredIfAnyDefined(passportFields),
            },
            passport_reference: {
              required: requiredIfAnyDefined(passportFields),
            },
            expiration_date: {
              required: requiredIfAnyDefined(passportFields),
            },
            file_path: {
              required: requiredIfAnyDefined(passportFields),
            },
          },
          visa: {
            visa_reference: {
              required: requiredIf(() => this.visaNeeded),
            },
            file_path: {
              required: requiredIf(() => this.visaNeeded),
            },
          },
        },
        assistance: {
          type: {
            required: requiredIf(() => this.formData.assistance.is_required),
          },
        },
        dietary_requirements: {
          list: {
            required: requiredIf(
              () => this.formData.dietary_requirements.is_required
            ),
            minLength: minLength(1),
          },
        },
      },
    };
  },
  computed: {
    controlDate() {
      return {
        timeZone: guessTimezone(),
        minDate: sub(new Date(), { years: 100 }),
        maxDate: add(new Date(), { years: 3 }),
      };
    },
    piiData() {
      return readPiiData(this.$store);
    },
    guestData() {
      return this.piiData.guests.find((item) => item.id === this.guestPiiToken);
    },
    countryList() {
      return countries.map((item: { name: string; code: string }) => ({
        id: item.code,
        name: item.name,
      }));
    },
    nationalityList() {
      return nationalities.map((item: string) => ({ id: item, name: item }));
    },
    pageTitle() {
      const guestIndex = this.piiData?.guests.findIndex(
        (item) => item.id === this.guestPiiToken
      );

      if (guestIndex < 1) {
        return this.$t('postSales.leadTraveler');
      }

      return `Guest ${guestIndex + 1}`;
    },
    storageUrl() {
      return `${
        getAppVariable('regional_config').domains.booking
      }/${getAppVariable(
        'api_version'
      )}/account/pii-questions-data/signed-storage-url`;
    },
    dropdownValues() {
      return ['nationality', 'issuing_country'];
    },
    computedIssuingCountry: {
      get() {
        return (
          this.countryList.find(
            (item) => item.id === this.formData.uploads.passport.issuing_country
          ) || {
            id: '',
            name: '',
          }
        );
      },
      set(val): void {
        this.formData.uploads.passport.issuing_country = val.id;
      },
    },
    computedNationality: {
      get() {
        return (
          this.nationalityList.find(
            (item) => item.id === this.formData.uploads.passport.nationality
          ) || {
            id: '',
            name: '',
          }
        );
      },
      set(val): void {
        this.formData.uploads.passport.nationality = val.id;
      },
    },
    computedAssistanceType: {
      get() {
        return (
          this.assistanceTypeOptions.find(
            (item) => item.id === this.formData.assistance.type
          ) || {
            id: '',
            name: '',
          }
        );
      },
      set(val): void {
        this.formData.assistance.type = val.id;
      },
    },
  },
  async mounted() {
    this.guestPiiToken = this.$cookies.get('guestPiiData')?.piiToken;
    this.populateState();
    await this.getDocumentPreviews();
  },
  methods: {
    async getDocumentPreviews() {
      console.log('=======GUEST DATA=====', this.guestData);
      if (!this.guestData.uploads) return;
      if (this.guestData.uploads?.passport?.file_path) {
        const { url } = await getShareObjectUrl(this.getToken, {
          key: this.guestData.uploads.passport.file_path,
        });
        this.documentPreviews.passport = url;
      }
      if (this.guestData.uploads?.visa?.file_path) {
        const { url } = await getShareObjectUrl(this.getToken, {
          key: this.guestData.uploads.visa.file_path,
        });
        this.documentPreviews.visa = url;
      }
    },
    async goToGuestDetails() {
      await this.$router.push(NAMED_ROUTES.booking_guest_details.home);
    },
    populateState() {
      Vue.set(this, 'formData', merge(this.formData, this.guestData));
    },
    async submitForm() {
      this.$v.formData.$touch();
      if (this.$v.$invalid) return;
      try {
        await postGuestDetails(
          this.getToken,
          this.$cookies.get('guestPiiData').orderId,
          this.prepareFormData(this.formData)
        );

        this.$cookies.remove('guestPiiData');
        await this.$router.push(NAMED_ROUTES.booking_guest_details.home);
      } catch (e) {
        console.log(e);
      }
    },
    prepareFormData(obj: any): any {
      if (Array.isArray(obj)) {
        return obj
          .filter((item) => item != null && item !== '')
          .map(this.prepareFormData);
      } else if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj)
          .filter(
            ([_, value]) =>
              value != null &&
              value !== '' &&
              !(Array.isArray(value) && value.length === 0)
          )
          .reduce((acc, [key, value]) => {
            if (
              this.dropdownValues.includes(key) &&
              typeof value === 'object' &&
              'id' in value
            ) {
              acc[key] = value.id;
            } else {
              acc[key] = this.prepareFormData(value);
            }
            return acc;
          }, {} as any);
      }
      return obj;
    },
    fileUpload(field: string, event) {
      this.formData.uploads[field].file_path = event.key;
    },
  },
});
