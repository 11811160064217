
import { defineComponent } from 'vue';
import type { ModalData } from '@white-label-types/modal';
import { GlobalModalError } from 'ui-shared-components';

type ComponentTypes = 'GlobalModalError';
type DefaultModalDataType = { [key in ComponentTypes]: ModalData };

const DEFAULT_MODAL_DATA: DefaultModalDataType = {
  GlobalModalError: {
    closeOnClickOutside: false,
    isSuccess: false,
    isWarning: false,
    header: '',
    body: '',
    btnType: 'custom',
  },
} as const;

export default defineComponent({
  name: 'ModalContainer',
  components: {
    GlobalModalError,
  },

  data() {
    return {
      component: 'GlobalModalError',
      data: null as ModalData | null,
    };
  },

  computed: {
    getComponent() {
      if (this.component && this.data?.header) {
        return GlobalModalError;
      }
      return null;
    },
  },

  watch: {
    '$route.name': function routeName(): void {
      this.close();
    },
  },

  mounted(): void {
    this.$global_eventEmitter.$on('setModalData', this.setModalDataListener);
  },

  beforeDestroy(): void {
    this.$global_eventEmitter.$off('setModalData', this.setModalDataListener);
  },

  methods: {
    preparseModalData(data: ModalData, component: ComponentTypes): ModalData {
      return {
        ...DEFAULT_MODAL_DATA[component],
        ...data,
      };
    },

    setModalDataListener({
      component,
      data,
    }: {
      component: ComponentTypes;
      data: ModalData;
    }): void {
      this.data = this.preparseModalData(data, component);
      this.component = component;
      document.querySelector('body')?.classList.add('no-animation');
    },

    close(callback?: Function): void {
      window.dispatchEvent(
        new CustomEvent('modalClose', {
          detail: callback,
        })
      );
      this.data = null;
      this.component = '';
      document.querySelector('body')?.classList.remove('no-animation');
    },
  },
});
