import { getStoreAccessors } from 'typesafe-vuex';
import { RootState } from '@white-label-types/stores';
import { fetchCartItems, AddToCartData, addToCart, deleteCartItem, fetchCart} from '@white-label-helper/api-parking-cart';
import { getFeatureFlag } from '@white-label-plugin/launch-darkly';
import { ActionContext } from 'vuex';

import type { SearchCriteria } from '@white-label-types/product-types';
import type {
    ProductOptions,
    GenerateProduct,
    ExtrasCancellationProtectionProduct,
    BookingFee,
    Totals
} from '@white-label-types/parking-booking';
interface CartProduct extends GenerateProduct{
    seach_criteria: SearchCriteria;
}

export type ProductsType = CartProduct | ExtrasCancellationProtectionProduct;

type Cart = {items: ProductsType[], item: ProductsType, totals: Totals, booking_fee?: BookingFee }
export type State = {
    token: string | null;
    items: (ProductsType)[];
    cancellationProtection: null | ProductOptions['details']['cancellation_protection'] | ExtrasCancellationProtectionProduct['cancellation_protection']
    addedExtras: string[];
    totals: null | Totals;
    bookingFee: null | BookingFee;
    itemIndex: null | number;
    itemListName: string;
    lastKnownFilter: string;
    isMultiBasket: boolean;
    latestItem: null|ProductsType;
    cartUpdated: boolean;
    cartLoading: boolean;
}

type CartContext = ActionContext<State, RootState>;

const cartState = (): State => ({
    token: null,
    items: [],
    cancellationProtection: null,
    addedExtras: [],
    totals: null,
    bookingFee: null,
    itemIndex: null,
    itemListName: '',
    lastKnownFilter: '',
    isMultiBasket:false,
    latestItem: null,
    cartUpdated:false,
    cartLoading:false,
});

const cartGetters = {
    token: (state: State): string | null => state.token,
    items: (state: State): State['items'] => state.items,
    totals: (state: State): Totals | null => state.totals,
    bookingFee: (state: State): BookingFee | null => state.bookingFee,
    addedExtras: (state: State): string[] => state.addedExtras,
    cancellationProtection: (state: State): State['cancellationProtection'] => state.cancellationProtection,
    itemIndex: (state: State): State['itemIndex'] => state.itemIndex,
    itemListName: (state: State): State['itemListName'] => state.itemListName,
    lastKnownFilter: (state: State): State['lastKnownFilter'] => state.lastKnownFilter,
    isMultiBasket: (state: State): State['isMultiBasket'] => state.isMultiBasket,
    latestItem: (state: State): State['latestItem'] => state.latestItem,
    cartUpdated: (state: State): State['cartUpdated'] => state.cartUpdated,
    cartLoading: (state: State): State['cartLoading'] => state.cartLoading,
};

const mutations = {
    storeItem(state: State, itemToStore: ProductsType) {
        // Prevent adding duplicates in array.
        const index = state.items.findIndex((item) => item.id === itemToStore.id);

        if (index !== -1) {
            Object.assign(state.items[index], itemToStore);
        } else {
            state.items.push(itemToStore);
        }
       state.latestItem = itemToStore;
    },
    storeItemList(state: State, items: ProductsType[]) {
        state.cartUpdated = false;
        state.items = items
    },
    storeIsMultiBasket(state: State, payload: boolean) {
        state.isMultiBasket = payload;
    },
    storeCartUpdated(state: State, payload: boolean) {
        state.cartUpdated = payload;

    },
    storeTotals(state: State, payload: Totals) {
        state.totals = payload;
    },
    storeBookingFee(state: State, payload: BookingFee) {
        state.bookingFee = payload;
    },
    storeLatestItem(state: State, payload: ProductsType) {
        state.latestItem = payload;
    },
    storeToken(state: State, payload: string) {
        state.token = payload;
    },
    clearCart(state: State, newToken: string | null = null) {
        state.items = [];
        state.token = newToken;
        state.cancellationProtection = null;
        state.addedExtras = [];
        state.totals = null;
    },
    storeCancellationProtection(state: State, payload: State['cancellationProtection']) {
        state.cancellationProtection = payload;
    },
    addExtra(state: State, id: string) {
        state.addedExtras.push(id);
    },
    removeExtra(state: State, id: string) {
        state.addedExtras = state.addedExtras.filter((i) => i !== id);
    },
    storeItemIndex(state: State, index: number) {
        state.itemIndex = index;
    },
    storeItemListName(state: State, listName: string) {
        state.itemListName = listName;
    },
    storeLastKnownFilter(state: State, filter: string) {
        state.lastKnownFilter = filter;
    },
    removeCartItem(state: State, id: string) {
        state.items = state.items.filter((item) => item.id !== id);
    },
    storeCartLoading(state: State, isLoading: boolean) {
        state.cartLoading = isLoading;
    },
};

const actions = {
    async fetchCartItems({ commit, state }: CartContext) {
        try {
            if (state.token === null) {
                throw new Error('No token provided');
            }
            const cartData = await fetchCartItems(state.token);
            commit('deposits/storeDepositDetails', {
                ...cartData.payable,
                isEnabled: getFeatureFlag('gtm-322-deposit-only-products')
            }, { root: true });
            commit('storeItem', cartData.items[0]);
            commit('storeTotals', cartData.totals);
            commit('storeBookingFee', cartData.booking_fee);
            commit('storeCancellationProtection', cartData.items[0].cancellation_protection);
        } catch (e) {
            commit('clearCart');
            throw e;
        }
    },
    async fetchCart({ commit, state }: CartContext): Promise<Cart>{
        if (state.token === null) {
            throw new Error('No token provided');
        }
        commit('storeCartLoading', true);
        const response = fetchCart<ProductsType, Totals>(state.token);
        response
          .then(({ items, totals }: Cart) => {
              commit('storeItemList', items);
              commit('storeTotals', totals);
              commit('storeCartLoading', false);
          })
          .catch(() => {
          });
        return response
    },
    async addToCart({ commit } : CartContext, data: AddToCartData):  Promise<Cart> {

        const response = addToCart<ProductsType, Totals>(data) ;
        response.then(({ items, totals, booking_fee }: Cart)=> {
            const item = items[items.length - 1];
            commit('storeItem', item);
            commit('storeTotals', totals);
            commit('storeCartUpdated', true);
            const cancellationProtection = item?.inventory_option?.details?.cancellation_protection;
            const isCancellationProtectionAvailable =
            cancellationProtection.is_available;
            if (isCancellationProtectionAvailable) {
                commit('storeBookingFee', booking_fee);
                commit('storeCancellationProtection', cancellationProtection);
              }
        })
        return response;
    },
    async removeFromCart({ commit, state }: CartContext, itemId: string) {
        if (state.token === null) {
            throw new Error('No token provided');
        }
        const response = deleteCartItem(state.token as string, itemId);
        response.then(({ items, totals })=>{
            commit('removeCartItem', itemId);
            if(items.length === 0){
                commit('clearCart')
            }
            commit('storeTotals', totals)
        });
        return response;
    },
    readOrCreateCartToken(store: ActionContext<State, RootState>, uuid: string): string {
        let cartToken = readToken(store);
        if (!cartToken) {
            cartToken = uuid;
            commitStoreToken(store, cartToken);
        }
        return cartToken;
    }
};

const { commit, read, dispatch } = getStoreAccessors<State, RootState>('cart');

export const readCartItems = read(cartGetters.items);
export const readToken = read(cartGetters.token);
export const readItems = read(cartGetters.items);
export const readTotals = read(cartGetters.totals);
export const readBookingFee = read(cartGetters.bookingFee);
export const readAddedExtras = read(cartGetters.addedExtras);
export const readCancellationProtection = read(cartGetters.cancellationProtection);
export const readItemIndex = read(cartGetters.itemIndex);
export const readItemListName = read(cartGetters.itemListName);
export const readLastKnownFilter = read(cartGetters.lastKnownFilter);
export const readCartUpdated = read(cartGetters.cartUpdated);
export const readLatestItem = read(cartGetters.latestItem);
export const readCartLoading = read(cartGetters.cartLoading);

export const commitStoreItem = commit(mutations.storeItem);
export const commitStoreItemList = commit(mutations.storeItemList);
export const commitStoreTotals = commit(mutations.storeTotals);
export const commitStoreToken = commit(mutations.storeToken);
export const commitClearCart = commit<string | null | undefined>(mutations.clearCart);
export const commitStoreCancellationProtection = commit(mutations.storeCancellationProtection);
export const commitStoreBookingFee = commit(mutations.storeBookingFee);
export const commitAddExtra = commit(mutations.addExtra);
export const commitRemoveExtra = commit(mutations.removeExtra);
export const commitStoreItemIndex = commit(mutations.storeItemIndex);
export const commitStoreItemListName = commit(mutations.storeItemListName);
export const commitLastKnownFilter = commit(mutations.storeLastKnownFilter);
export const commitIsMultiBasket = commit(mutations.storeIsMultiBasket);
export const commitCartUpdated = commit(mutations.storeCartUpdated);

export const commitCartLoading = commit(mutations.storeCartLoading);

export const dispatchFetchCart = dispatch(actions.fetchCart);
export const dispatchFetchCartItems = dispatch(actions.fetchCartItems);
export const dispatchAddToCart = dispatch(actions.addToCart);
export const dispatchRemoveFromCart = dispatch(actions.removeFromCart);
export const readOrCreateCartToken = actions.readOrCreateCartToken;

export default {
    state: cartState,
    getters: cartGetters,
    mutations,
    actions,
};
