
import { defineComponent } from 'vue';
import flatPickr from 'vue-flatpickr-component';
import {
  add, format, DATE_TIME_FORMATS,
} from '@white-label-helper/date-utilities';
import IconButton from '@white-label-icon/icon-button';
// Types
import type { CustomLocale } from 'flatpickr/types/locale';

// flatpickr locales
import { French as fr } from 'flatpickr/dist/l10n/fr.js';
import { english as en } from 'flatpickr/dist/l10n/default.js';
import { Spanish as es } from 'flatpickr/dist/l10n/es.js';
import { Polish as pl } from 'flatpickr/dist/l10n/pl.js';
import { Italian as it } from 'flatpickr/dist/l10n/it.js';
import { German as de } from 'flatpickr/dist/l10n/de.js';
import { Portuguese as pt } from 'flatpickr/dist/l10n/pt.js';

type Config = {
  showMonths?: number,
  inline?: boolean,
  static?: boolean,
  minDate?: string | Date
  maxDate?: string | Date
  monthSelectorType?: 'static' | 'dropdown',
  locale?: CustomLocale,
}

type Locales = 'en' | 'fr' | 'es' | 'pl' | 'it' | 'de' | 'pt';

export default defineComponent({
  components: {
    flatPickr,
    IconButton
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    minDate: {
      type: Date,
      default: () => new Date(),
    },

    maxDate: {
      type: Date,
      default: () => add(new Date(), { years: 1 }),
    },

    timeZone: {
      type: String,
      default: '',
    },

    isMobilePopUp: {
      type: Boolean,
      default: false,
    },

    mobilePopUpTitle: {
      type: String,
      required: false,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      locales: { fr, en, es, pl, it, de, pt },
    };
  },

  computed: {
    showMonth (): number {
      return this.isMobileResolution ? 1 : 2;
    },

    twoLetterLanguageCode(): Locales {
      return `${this.$i18n.locale.slice(0,2)}` as Locales;
    },

    computedLocales() {
      const obj = {} as {
        [key: string]: CustomLocale,
      };

      Object.keys(this.locales).map((key) => {
        switch (key) {
          case 'en':
            obj[key] = {
              ...this.locales[key],
              weekdays: {
                shorthand: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                longhand: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
              },
            }
            break;
          case 'fr':
          case 'es':
          case 'pl':
          case 'it':
          case 'pt':
            obj[key] = {
              ...this.locales[key],
              months: {
                shorthand: this.locales[key].months.shorthand,
                longhand: this.locales[key].months.longhand.map((item) => item.toLowerCase()),
              }
            }
            break;
          case 'de':
            obj[key] = this.locales[key];
            break;
        }
      });

      return obj;
    },

    flatpickrConfig (): Config {
      return {
        showMonths: this.showMonth,
        inline: true,
        static: true,
        minDate: this.minDate,
        maxDate: this.maxDate,
        monthSelectorType: 'static',
        locale: this.computedLocales[this.twoLetterLanguageCode],
      };
    },
  },

  methods: {
    onChange ($event: (number | Date)[]): void {
      const formattedDate = format($event[0], DATE_TIME_FORMATS.year_month_day);

      this.$emit('input', formattedDate);
      this.$emit('on-change', formattedDate);
    },
  },
});
