
// Packages
import { PropType, defineComponent } from 'vue';

// Helpers
import { guessTimezone } from '@white-label-helper/date-utilities';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { checkIsLessOneHour } from '@white-label-helper/time-helpers';
import {
  goToApp,
  getDomainUrl,
  DOMAINS_NAMES,
  ECOMMERCE_URLS,
  MANAGE_BOOKING_URLS,
} from '@white-label-helper/switch-app';

// Types
import type { Partners } from '@white-label-types/partners';
import { NAMED_ROUTES } from '@white-label-configuration/constants';

// Icons
import IconAlert from '@white-label-icon/icon-alert';
import IconMenuRight from '@white-label-icon/icon-menu-right';
import IconWarning from '@white-label-icon/icon-warning';
import IconArrowDown from '@white-label-icon/icon-arrow-down';

// Stores
import {
  readIsConfirmed,
  readIsInProgress,
  readStringEntryTime,
  readIsAmendable,
  readIsCancellable,
  readProductCode,
  readIsCancelled,
} from '@white-label-store/manage-booking';

// Components
import IconMessage from '../icon-message/icon-message.vue';
import ControlButton from '../control-button/control-button.vue';
import ManageBookingNavigationItem from '../manage-booking-navigation-item/manage-booking-navigation-item.vue';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import BTicketShareButton from '../b-ticket-share-button/b-ticket-share-button.vue';
import { ManageBookingState } from '@white-label-types/stores';
import ManageBookingShareButton from '../manage-booking-share-button/manage-booking-share-button.vue';

type MenuItem = {
  path?: (typeof NAMED_ROUTES.manage_booking)[keyof typeof NAMED_ROUTES.manage_booking];
  icon: 'User' | 'Parking' | 'MyBooking' | 'Cancel' | 'LoungeBooking';
  name: string;
  action: 'updatePersonalDetails' | 'amend' | 'cancel';
  actionType: 'route' | 'modifyFlow';
  isActive: boolean;
  id?: string;
};

export default defineComponent({
  name: 'ManageBookingNavigation',

  components: {
    ControlButton,
    IconAlert,
    IconMenuRight,
    IconMessage,
    IconWarning,
    IconArrowDown,
    MBNavigationItem: ManageBookingNavigationItem,
    BTicketShareButton,
    ManageBookingShareButton,
  },

  props: {
    orderData: {
      type: Object as PropType<ManageBookingState['manageBookingState']>,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      displayEmailReceiptModal: false,
      isMobileMenuVisible: false,
      isLessOneHour: false,
      tryAgainCount: 0,
    };
  },

  computed: {
    isCancelled(): ReturnType<typeof readIsCancelled> {
      return readIsCancelled(this.$store);
    },

    isShareButtonOnlyItem(): boolean {
      return (
        this.menuItems.filter((item: MenuItem) => {
          if (
            item.isActive &&
            item.name !== 'manageBooking.menu.cancelBooking'
          ) {
            return item;
          }
          return;
        }).length === 0
      );
    },
    isCrossSellAndMultiItem() {
      // TODO: Remove condition when ECM_2_CROSS_SELL_POST_PURCHASE is enabled
      return (
        this.orderData?.items.length > 1 &&
        getAppHeroProduct('parking') === 'parking' &&
        this.$launchDarkly.variation('ECM_2_CROSS_SELL_POST_PURCHASE')
      );
    },

    isConfirmed(): boolean {
      return readIsConfirmed(this.$store);
    },

    isInProgress(): boolean {
      return readIsInProgress(this.$store);
    },

    entryDateTime(): string {
      return readStringEntryTime(this.$store);
    },

    isAmendable(): boolean | undefined {
      return readIsAmendable(this.$store);
    },

    isCancellable(): boolean {
      return readIsCancellable(this.$store);
    },

    productCode(): string {
      return readProductCode(this.$store);
    },

    partnerTimeZone(): Partners['poi']['timezone'] {
      return getAppVariable('poi.timezone') || guessTimezone();
    },

    /**
     * Order is cancellable if:
     * - Order level "is_cancellable" attribute is true
     * - At least one cancellable order item is more than an hour away from entry date
     */
    orderIsCancellable (): boolean {
      return this.isCancellable
        && this.anyCancellableItemIsWithinCancellablePeriod;
    },

    /**
     * Returns true if at least one cancellable order item is more than an hour away from entry date
     */
    anyCancellableItemIsWithinCancellablePeriod (): boolean {
      return this.orderData.items
        .filter((item) => item.is_cancellable)
        .some((item) => {
          return !checkIsLessOneHour(item.entryExitInfo.stringEntryTime, this.partnerTimeZone);
      });
    },

    /**
     * Returns true if at least one order item is more than an hour away from entry date
     */
    anyItemIsWithinActionablePeriod (): boolean {
      return this.orderData.items
        .some((item) => {
          return !checkIsLessOneHour(item.entryExitInfo.stringEntryTime, this.partnerTimeZone);
      });
    },

    menuItems(): MenuItem[] {
      return [
        {
          path: NAMED_ROUTES.manage_booking.update_details,
          icon: 'User',
          name: 'manageBooking.menu.updatePersonalDetails',
          id: 'GAUpdateDetails',
          action: 'updatePersonalDetails',
          actionType: 'route',
          isActive: !this.isLessOneHour && !this.isInProgress,
        },
        {
          icon: this.productCode === 'parking' ? 'Parking' : 'LoungeBooking',
          name:
            this.productCode === 'parking'
              ? 'manageBooking.menu.modifyParking'
              : 'manageBooking.menu.amendLounge',
          id:
            this.productCode === 'parking'
              ? 'GAModifyParking'
              : 'GAAmendLounge',
          action: 'amend',
          actionType: 'modifyFlow',
          isActive: !!(
            this.isAmendable &&
            !this.isLessOneHour &&
            !this.isInProgress && !this.isCrossSellAndMultiItem
          ),
        },
        {
          path: NAMED_ROUTES.manage_booking.cancel,
          icon: 'Cancel',
          name: 'manageBooking.menu.cancelBooking',
          id: 'GACancelParking',
          action: 'cancel',
          actionType: 'route',
          isActive: this.orderIsCancellable,
        },
      ];
    },
  },

  mounted() {
    document.body.addEventListener('click', this.closeModal);

    this.isLessOneHour = checkIsLessOneHour(
      this.entryDateTime,
      this.partnerTimeZone
    );
  },

  beforeDestroy() {
    document.body.addEventListener('click', this.closeModal);
  },

  methods: {
    stylesForShareButton(): string {
      if(this.isShareButtonOnlyItem) {
        return 'pb-4 md:pb-0'
      }
      const hasAmendItem = this.menuItems.some((item: MenuItem) =>
          item.isActive &&
          item.name === 'manageBooking.menu.amendBooking'
          || item.isActive && item.name === 'manageBooking.menu.modifyParking'
      )

      return hasAmendItem ? 'pl-0' : 'pl-8';
    },

    toggleVisibleNavigationMenu() {
      this.isMobileMenuVisible = !this.isMobileMenuVisible;
    },

    closeModal() {
      this.isMobileMenuVisible = false;
    },

    showNavigationActionError(action: MenuItem['action']) {
      let messageKey = '';
      if (action === 'updatePersonalDetails') {
        messageKey = 'bookingCannotBeUpdated';
      } else if (action === 'amend') {
        messageKey = 'bookingCannotBeAmended';
      } else {
        messageKey = 'bookingCannotBeCancelled';
      }

      this.$openModal('GlobalModalError', {
        header: this.$t(`shared.modals.errors.${messageKey}.header`),
        body: this.$t(`shared.modals.errors.${messageKey}.body`),
        btnText: this.$t('shared.buttons.close'),
        btnType: 'custom',
      }).catch(() => {});
    },

    navigationAction(item: MenuItem) {
      const { action, actionType, path } = item;

      this.isLessOneHour = checkIsLessOneHour(
        this.entryDateTime,
        this.partnerTimeZone
      );

      if (
        (this.orderData.items.length === 1 && this.isLessOneHour)
        || (this.orderData.items.length > 1 && !this.anyItemIsWithinActionablePeriod)
      ) {
        this.showNavigationActionError(action);
        return;
      }

      if (actionType === 'route' && typeof path !== 'undefined') {
        this.$router.push(this.localePath(path));
        return;
      }

      // TODO: remove condition below when CUS_1803 is ready for live
      // DEVELOPER NOTE: this exists to navigate to the manage booking flow that is hosted in the ecommerce app
      // this should be remove then the work to move all of manage booking flow to the account app.
      if (
        !this.$launchDarkly.variation(
          'ENABLE_MUTLIPLE_PRODUCT_SUPPORT_MYACCOUNT_CUS_1803'
        ) &&
        actionType === 'modifyFlow' &&
        this.productCode === 'lounges'
      ) {
        this.$router.push(MANAGE_BOOKING_URLS.MODIFY_SEARCH);
        return;
      }

      if (actionType === 'modifyFlow') {
        goToApp(
          getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
          ECOMMERCE_URLS.MODIFY,
          `amendItemId=${this.getAmendItemId()}`
        );
        return;
      }

      if (this.tryAgainCount < 3) {
        this.tryAgainCount += 1;
        this.$openModal('GlobalModalError', {
          header: this.$t('shared.modals.errors.technicalIssue.header'),
          body: this.$t('shared.modals.errors.technicalIssue.body'),
          btnText: this.$t('shared.buttons.tryAgain'),
        });
      } else {
        this.$openModal('GlobalModalError', {
          header: this.$t('shared.modals.errors.technicalIssue.header'),
          body: this.$t('shared.modals.errors.technicalIssue.body'),
          btnText: this.$t('shared.buttons.tryAgainLater'),
        });
      }

      this.$emit('action-checker', { actionType, path });
    },
    getAmendItemId() {
      return this.orderData?.items[0]?.id;
    }
  },
});
