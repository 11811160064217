
import { defineComponent } from 'vue';
import BookingStatus from '../booking-status/booking-status.vue';
import ControlButton from '../button/button.vue';
import { BOOKING_STATUSES } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'BookingDetailsCard',
  components: { ControlButton, BookingStatus },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'incomplete',
    },
  },
  computed: {
    buttonMeta() {
      return this.status === BOOKING_STATUSES.complete
        ? {
            type: 'secondary',
            label: this.$t('guestDetails.edit'),
          }
        : {
            type: 'primary',
            label: this.$t('guestDetails.addDetails'),
          };
    },
  },
});
