import { APP_HERO_PRODUCTS, HeroProduct } from '@white-label-configuration/constants'

export function getAppHeroProduct(defaultValue: HeroProduct): HeroProduct {

  const heroProduct: string | undefined = process.env['NUXT_ENV_DEFAULT_HERO_PRODUCT']

  if (heroProduct === undefined) {
    throw new ReferenceError('The "NUXT_ENV_DEFAULT_HERO_PRODUCT" environment variables must be set')
  }

  if (heroProduct === '') {
    throw new ReferenceError('The "NUXT_ENV_DEFAULT_HERO_PRODUCT" environment variables must be a string')
  }

  const heroProductRecognised: boolean = Object.values(APP_HERO_PRODUCTS).includes(heroProduct as HeroProduct)

  if (heroProductRecognised) {
    return heroProduct as HeroProduct
  }

  // Warn and return the default value if not set
  console.warn(`You have not set a default hero product to fall back to`);
  return defaultValue
}
