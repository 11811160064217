import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _24eddb93 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _54c1bfd0 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _492ca57a = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _172982ee = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _217b64c8 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _dd66b4f8 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4afb1424 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _67787b69 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _35603206 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _52b59529 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _28bfc136 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _76e189ce = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _770c1f36 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _0a0e6eb7 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _4c98429c = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _70b5fb26 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _24eddb93,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _54c1bfd0,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _492ca57a,
    name: "callback"
  }, {
    path: "/downloads",
    component: _172982ee,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _217b64c8,
    name: "guests-details"
  }, {
    path: "/login",
    component: _dd66b4f8,
    name: "login"
  }, {
    path: "/maintenance",
    component: _4afb1424,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _67787b69,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _35603206,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _52b59529,
    name: "search-booking"
  }, {
    path: "/downloads/ticket",
    component: _28bfc136,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _76e189ce,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _770c1f36,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _0a0e6eb7,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _4c98429c,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _70b5fb26,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
