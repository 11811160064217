// Types
import { Booking, Order } from '@white-label-types/account-booking';

/**
 * Parses the `/account/orders/:channel_id` response data into an array of Booking objects
 * containing only the fields needed by the MA app.
 * @param bookings order data from `/account/orders/:channel_id` endpoint
 * @returns an array of Bookings containing only fields needed on MA app.
 */
export function parseOrdersResponse(bookings: Order[]): Booking[] {
  return bookings.map((booking: Order) => {
    const { reference, order_items } = booking;
    let isPrivateTerminalBooking = false;
    const orderItems = order_items.map((orderItem) => {
      if (
        orderItem?.order_inventory?.airline_name ||
        orderItem?.order_inventory?.baggage_type ||
        orderItem?.order_inventory.meal_type
      ) {
        isPrivateTerminalBooking = true;
      }
      return {
        id: orderItem?.id,
        displayName: orderItem?.order_inventory?.display_name,
        direction: orderItem?.order_inventory?.direction,
        airlineName: orderItem?.order_inventory?.airline_name,
        baggageType: orderItem?.order_inventory?.baggage_type,
        mealType: orderItem?.order_inventory?.meal_type,
        startedAt: orderItem?.product?.started_at,
        closedAt: orderItem.product?.closed_at,
        address: orderItem?.product?.poi?.address,
        groups: {
          adult:
            orderItem?.inventory_option?.details?.groups?.adult?.totals
              ?.quantity ?? 0,
          child:
            orderItem?.inventory_option?.details?.groups?.child?.totals
              ?.quantity ?? 0,
          infant:
            orderItem?.inventory_option?.details?.groups?.infant?.totals
              ?.quantity ?? 0,
          senior:
            orderItem?.inventory_option?.details?.groups?.senior?.totals
              ?.quantity ?? 0,
        },
      };
    });
    const orderItem = order_items[order_items.length - 1];
    const product = orderItem.product;
    const startDateTime = product?.started_at?.datetime.split('T');
    const closedDateTime = product?.closed_at?.datetime.split('T');

    return {
      id: reference,
      product_code: orderItem?.product_code,
      status: orderItem?.status,
      privateTerminalBooking: isPrivateTerminalBooking,
      startDatetime: {
        date: startDateTime ? startDateTime[0] : '',
        time: startDateTime ? startDateTime[1] : '',
      },
      endDatetime: {
        date: closedDateTime ? closedDateTime[0] : '',
        time: closedDateTime ? closedDateTime[1] : '',
      },
      orderItems,
    };
  });
}
