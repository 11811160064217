import { datadogRum } from '@datadog/browser-rum';
import Pusher from 'pusher-js';

import { getFeatureFlag } from '@white-label-plugin/launch-darkly';
import { getAppVariable } from '@white-label-helper/get-app-variable';

let _instance: Pusher | undefined;

/**
 * A helper function to reset the instance.
 * This is used for testing purposes only.
 */
export function _resetChannel(): void {
  if (process.env.NODE_ENV === 'production') {
    throw new Error('This function should not be called in production');
  }

  _instance = undefined;
}

/**
 * Get the pusher instance
 *
 * @returns {Pusher}
 */
export const getPusherHelper = () => {
  if (getFeatureFlag('AP-398_ADD_REGION_SUPPORT_TO_PUSHER') === true) {
    const regionalConfig = getAppVariable('regional_config');
    return getPusherInstance(regionalConfig.pusher.key, regionalConfig.pusher.cluster);
  } else {
    return getPusherInstance();
  }
}

/**
 * Get the pusher instance
 *
 * This function will create the channel if it has not been created before. Subsequent calls will
 * return the same channel.
 *
 * @Note This function will throw an error if the key argument is not provided or environment variable NUXT_ENV_PUSHER_APP_KEY
 * is not set.
 *
 * @Note This function will throw an error if the cluster argument is not provided or environment variable NUXT_ENV_PUSHER_APP_CLUSTER
 * is not set.
 *
 * See https://pusher.com/docs/channels/using_channels/connection/
 *
 * @returns {Pusher} The pusher instance
 */
export const getPusherInstance = (key?: string, cluster?: string): Pusher => {
  if (_instance !== undefined) {
    return _instance;
  }

  const pusherKey = key ?? process.env.NUXT_ENV_PUSHER_APP_KEY;

  const pusherKeyIsUndefined = pusherKey === undefined
  const pusherKeyIsNotAString = typeof pusherKey !== 'string'

  if (pusherKeyIsUndefined || pusherKeyIsNotAString) {
    throw new TypeError('NUXT_ENV_PUSHER_APP_KEY has not been set');
  }

  const pusherCluster = cluster ?? process.env.NUXT_ENV_PUSHER_APP_CLUSTER;

  const pusherClusterIsUndefined = pusherCluster === undefined
  const pusherClusterIsNotAString = typeof pusherCluster !== 'string'

  if (pusherClusterIsUndefined || pusherClusterIsNotAString) {
    throw new TypeError('NUXT_ENV_PUSHER_APP_CLUSTER has not been set');
  }

  _instance = new Pusher(pusherKey, {
    cluster: pusherCluster
  });

  // When a connection error occurs, log an error to the reporting platform.
  _instance.connection.bind('error', (error: unknown) => {
    datadogRum.addError(error);
  });

  return _instance;
}
