import { setupLinkProtocol } from '@white-label-helper/setup-link-protocol';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { DOMAINS } from './constants';
import { DomainNames } from '../types';

/**
 * This function retrieves the domain URL from the whitelabel_host_domains
 * partner configuration;
 * Returns default value from NUXT_ENV_${DOMAIN}_URL if it is running locally;
 * @param {DomainNames} domainName
 */
export function getDomainUrl(domainName: DomainNames): string | null {

  // Override the domain URL if it is set in the environment variables
  switch (domainName) {
    case "ACCOUNT":
      if (process.env.NUXT_ENV_ACCOUNT_APP_HOST_DOMAIN_OVERRIDE !== undefined) {
        return setupLinkProtocol(process.env.NUXT_ENV_ACCOUNT_APP_HOST_DOMAIN_OVERRIDE);
      }
      break;
    case "CHECKOUT":
      if (process.env.NUXT_ENV_CHECKOUT_APP_HOST_DOMAIN_OVERRIDE !== undefined) {
        return setupLinkProtocol(process.env.NUXT_ENV_CHECKOUT_APP_HOST_DOMAIN_OVERRIDE);
      }
      break;
    case "ECOMMERCE":
      if (process.env.NUXT_ENV_ECOMMERCE_APP_HOST_DOMAIN_OVERRIDE !== undefined) {
        return setupLinkProtocol(process.env.NUXT_ENV_ECOMMERCE_APP_HOST_DOMAIN_OVERRIDE);
      }
      break;
  }

  if (window.location.hostname != 'localhost') {
    const domainKey = `page_links.whitelabel_host_domains.${domainName.toLocaleLowerCase()}`;
    const defaultValue = DOMAINS[domainName];
    return setupLinkProtocol(getAppVariable(domainKey, defaultValue)) || null;
  }

  return DOMAINS[domainName] || null;
}
