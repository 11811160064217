
// Packages
import { defineComponent } from 'vue';

// Types
import type { Partners } from '@white-label-types/partners-api';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';
import {
  ACCOUNT_URLS,
  DOMAINS_NAMES,
  PRODUCT,
  goToApp,
  getDomainUrl,
} from '@white-label-helper/switch-app';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';

// Components
import ScrollTopButton from '../scroll-top-button/scroll-top-button.vue';
import IconButton from '@white-label-icon/icon-button';
import PaymentLogos from './payment-logos.vue';
import CustomerSupportContact from '../footer-customer-support-contact/footer-customer-support-contact.vue';

// Assets
import logoPropelDark from './assets/logo-propel-dark.svg';
import logoPropelLight from './assets/logo-propel-light.svg';

// Constants
import {
  COLOUR_SCHEMES,
  NAMED_ROUTES,
} from '@white-label-configuration/constants';

type ColourScheme = (typeof COLOUR_SCHEMES)[keyof typeof COLOUR_SCHEMES];

export default defineComponent({
  name: 'EcomFooter',

  components: {
    CustomerSupportContact,
    PaymentLogos,
    ScrollTopButton,
    IconButton,
  },

  props: {
    isWlApp: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    company(): Partners['company'] {
      return {
        name: getAppVariable('company.name'),
        address: getAppVariable('company.address'),
        registrationNumber: getAppVariable('company.registered_number'),
      };
    },

    customerSupportContact(): Partners['customer_support_contact_details'] {
      return getAppVariable('customer_support_contact_details');
    },

    paymentLogos(): string[] {
      if (this.colourScheme === COLOUR_SCHEMES.LIGHT) {
        return [
          'mastercard_white',
          'visa',
          'american_express',
          'discover_white',
        ];
      }
      return ['mastercard', 'visa', 'american_express', 'discover'];
    },

    receiptPages(): string[] {
      return [
        'receipt',
        'manage-booking-receipt',
        'manage-booking-cancel-receipt',
      ];
    },

    modifyPages(): string[] {
      return [
        // post-purchase pages
        'manage-booking-update-details',
        'manage-booking-cancel',
        'manage-booking-cancel-receipt',
        'manage-booking-modify',
        'manage-booking-receipt',
        'modify-checkout',
      ];
    },

    loungesPages(): string[] {
      return [
        'my-profile',
        'manage-booking',
        'manage-booking-modify',
        'manage-booking-update-details',
        'manage-booking-cancel',
        'manage-booking-cancel-receipt',
      ];
    },

    navigationLinks(): { name: string; url: string }[] {

      const {
        terms_and_conditions,
        privacy_policy,
        cookie_policy,
      }: Partners['page_texts'] = getAppVariable('page_texts');
      const language = this.$i18n.locale;

      const privacyPolicyUrl =
        privacy_policy.type === 'text'
          ? this.isWlApp
            ? `${window.location.origin}/privacy-policy`
            : `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}/privacy-policy`
          : privacy_policy.value[language];

      const termsAndConditionsUrl =
        terms_and_conditions.type === 'text'
          ? this.isWlApp
            ? `${window.location.origin}/terms-and-conditions`
            : `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}/terms-and-conditions`
          : terms_and_conditions.value[language];

      const cookiePolicyUrl =
        cookie_policy.type === 'text'
          ? this.isWlApp
            ? `${window.location.origin}/cookie-policy`
            : `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}/cookie-policy`
          : cookie_policy.value[language];

      return [
        {
          name: this.$t('layouts.footerParkings.links.privacyPolicy') as string,
          url: privacyPolicyUrl,
        },
        {
          name: this.$t(
            'layouts.footerParkings.links.termsAndConditions'
          ) as string,
          url: termsAndConditionsUrl,
        },
        {
          name: this.$t('layouts.footerParkings.links.cookiePolicy') as string,
          url: cookiePolicyUrl,
        },
      ].filter((item) => item.url);
    },

    showScrollTopButton(): boolean {
      return this.$route.name !== 'index';
    },

    showCustomerSupportContact(): boolean {
      const allowedPages = this.customerSupportContact?.allowed_pages;

      const path = this.$route.name ?? '';
      const url = process.client ? window?.location?.href : '';

      const showOnModify = !!(
        allowedPages?.modify && this.modifyPages.includes(path)
      );
      const showOnReceipt = !!(
        allowedPages?.receipt && this.receiptPages?.includes(path)
      );
      let showOnMyAccount;

      if (PRODUCT.DEFAULT === 'lounges') {
        showOnMyAccount =
          allowedPages?.account && this.loungesPages.includes(path);
      }
      if (PRODUCT.DEFAULT === 'parking') {
        showOnMyAccount =
          allowedPages?.account &&
          url.includes(getDomainUrl( DOMAINS_NAMES.ACCOUNT));
      }

      return !!(showOnModify || showOnReceipt || showOnMyAccount);
    },

    colourScheme(): ColourScheme {
      return getAppVariable('colours.footer.font', COLOUR_SCHEMES.DARK);
    },

    logoSrc(): string {
      if (this.colourScheme === COLOUR_SCHEMES.DARK) return logoPropelDark;
      else return logoPropelLight;
    },
  },

  methods: {
    navigateToManageBooking(): void {
      const migrationSupport = getAppVariable('migration_support');

      if (migrationSupport?.is_in_progress) {
        window.location.href = migrationSupport.manage_booking_url;
        return;
      }

      const heroProduct = getAppHeroProduct('parking');

      // TODO: remove condition below when CUS_1803 is ready for live
      // DEVELOPER NOTE: this exists to navigate to the manage booking flow that is hosted in the ecommerce app
      // this should be remove then the work to move all of manage booking flow to the account app.
      if (
        !this.$launchDarkly.variation('ENABLE_MUTLIPLE_PRODUCT_SUPPORT_MYACCOUNT_CUS_1803')
        && heroProduct === 'lounges'
      ) {
        this.$router.push(NAMED_ROUTES.manage_booking.default);
        return;
      }

      let accountUrl: string;
      let accountUrlParams: string;

      // @ts-expect-error - global mixin computed property
      if (this.$isLoggedIn) {
        accountUrl = ACCOUNT_URLS.HOME;
      } else {
        accountUrl = ACCOUNT_URLS.SEARCH_BOOKING;
        accountUrlParams = '?action=search';
      }

      if (isBookingPortal) {
        accountUrl = '/orders';
        accountUrlParams = '?showOrdersList=true';
      }

      goToApp(
        getDomainUrl(DOMAINS_NAMES.ACCOUNT),
        accountUrl,
        accountUrlParams
      );
    },
  },
});
