import { getStoreAccessors } from 'typesafe-vuex';
import { RootState } from '@white-label-types/stores';
import { formatPrice } from '@white-label-helper/helper-payment';

const state = () => ({
  depositDetails: null as null | {
    payNow: string,
    payOnArrival: string
  }
});

type State = ReturnType<typeof state>;

const getters = {
  getDepositDetails: (state: State): State['depositDetails'] => state.depositDetails,
};

const mutations = {

  /**
   * Stores deposit details in the application state.
   *
   * @param state - The application state object.
   * @param details - Deposit details object.
   * @param details.pay_now - The amount to be paid upfront.
   * @param details.pay_on_arrival - The amount to be paid on arrival.
   */
  storeDepositDetails(state: State, {pay_now, pay_on_arrival, isEnabled = false}: {pay_now: number, pay_on_arrival: number, isEnabled: boolean}) {
    // We can use the "pay_on_arrival" to work out if it's a deposit-only product.
    // If the value is greater than 0, then it will be a deposit-only product
    if (isEnabled && pay_on_arrival > 0) {
      // We have a deposit-only item
      state.depositDetails = {
        payNow: formatPrice(pay_now).toString(),
        payOnArrival: formatPrice(pay_on_arrival).toString()
      }
    } else {
      state.depositDetails = null;
    }
  },
};

export default { namespace: true, state, getters, mutations };

const { commit, read } = getStoreAccessors<State, RootState>('deposits');

export const readDepositOnly = read(getters.getDepositDetails);
export const commitDepositOnly = commit(mutations.storeDepositDetails);
