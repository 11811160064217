import { Cart } from '@white-label-types/parking-checkout';

export function cartParser(cart: Cart) {
  const discountItem = cart.items.find((item) => item.discount !== null && Object.keys(item.discount).length);

  if (discountItem) {
    const { code, amount, description } = discountItem.discount;

    return {
      code,
      amount,
      description,
    }
  }

  return null;
}
