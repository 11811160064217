import { render, staticRenderFns } from "./b-ticket-guests.vue?vue&type=template&id=20003d9f&scoped=true"
import script from "./b-ticket-guests.vue?vue&type=script&lang=ts"
export * from "./b-ticket-guests.vue?vue&type=script&lang=ts"
import style0 from "./b-ticket-guests.vue?vue&type=style&index=0&id=20003d9f&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20003d9f",
  null
  
)

export default component.exports