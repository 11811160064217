
// Packages
import { PropType, defineComponent } from 'vue'

// Helpers
import {
  DOMAINS_NAMES,
  getDomainUrl,
  MANAGE_BOOKING_URLS,
} from '@white-label-helper/switch-app';

// Types
import type { Partners } from '@white-label-types/partners-api';

export default defineComponent({
  name: 'NavBarPartnerLink',

  components: {
    IconLink: () => import('@white-label-icon/icon-link')
  },

  props: {
    partnerName: {
      required: true,
      type: String as PropType<Partners['partner_name']>
    }
  },

  computed: {
    /** Send the customer to the search results page */
    url(): string {
      const domainUrl = getDomainUrl(DOMAINS_NAMES.ECOMMERCE);

      return domainUrl
        ? domainUrl.concat(MANAGE_BOOKING_URLS.BOOKING_SEARCH)
        : '/';
    },
  },
});
