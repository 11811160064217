// TODO Delete this file when we move to a hosting which supports Nuxt middleware
// Packages
import { getStoreAccessors } from 'typesafe-vuex';

// Types
import type {
  RootState,
  HelpersState,
  HelpersGetters,
  HelpersMutations,
} from '@white-label-types/stores';

const helperState = (): HelpersState => ({
  screenSize: 0,
  isAppMounted: false,
});

const getters: HelpersGetters = {
  getScreenSize: (state: HelpersState) => state.screenSize,

  isAppMounted(state): boolean {
    return state.isAppMounted;
  },
};

const mutations: HelpersMutations = {
  appInitialized(state, payload) {
    state.isAppMounted = payload;
  },
  storeScreenSize(state: HelpersState, payload: number) {
    state.screenSize = payload;
  },
};

const { commit, read } = getStoreAccessors<HelpersState, RootState>('helpers');

export const readScreenSize = read(getters.getScreenSize);
export const readIsAppMounted = read(getters.isAppMounted);

export const commitScreenSize = commit(mutations.storeScreenSize);
export const commitAppInitialized = commit(mutations.appInitialized);

export default {
  namespaced: true,
  state: helperState,
  mutations,
  getters,
};
